import { api } from './Salaries';

export const getCareerGuideListRequest = async () => {
  const response = await api.get('/career-guides');
  return response.data;
};

export const addCareerGuideRequest = async (data) => {
  const response = await api.post('/career-guides', data);
  return response.data;
};

export const getCareerGuideRequest = async (id) => {
  const response = await api.get(`/career-guides/${id}`);
  return response.data;
};

export const updateCareerGuideRequest = async (id, data) => {
  const response = await api.post(`/career-guides/${id}`, data);
  console.log(data, 'data from updateCareerGuideRequest');
  console.log(response, 'response from updateCareerGuideRequest');
  return response.data;
};

export const deleteCareerGuideRequest = async (id) => {
  const response = await api.delete(`/career-guides/${id}`);
  return response.data;
};
