import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useSnackbar } from 'notistack';

import { Link as RouterLink } from 'react-router-dom';
// material
import { useTheme } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Chip,
  Dialog
} from '@mui/material';
import TransitionsDialogs from '../../../../components/DeleteItemDialog';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useSettings from '../../../../hooks/useSettings';
// components
import Page from '../../../../components/Page';
import Label from '../../../../components/Label';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { deleteTutorRequest, getTutorListRequest } from 'src/_apis_/tuitions';
import TutorMoreMenu from 'src/components/_dashboard/tuitions/list/TutorMoreMenu';
import { UserListHead, UserListToolbar } from 'src/components/_dashboard/user/list';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'fullName', label: 'Name', alignRight: false },
  { id: 'phoneNumber', label: 'Phone', alignRight: false },
  // { id: 'subjects', label: 'Subjects', alignRight: false },
  // { id: 'daysAvailable', label: 'Days available', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      const lowerCaseQuery = query.toLowerCase();
      return (
        (_user.fullName && _user.fullName.toLowerCase().indexOf(lowerCaseQuery) !== -1) ||
        (_user.phoneNumber && _user.phoneNumber.toLowerCase().indexOf(lowerCaseQuery) !== -1) 
        // || (_user.subjects && _user.subjects.some((subject) => subject.title && subject.title.toLowerCase().indexOf(lowerCaseQuery) !== -1))
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}
export default function Tutors() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [tutors, setTutors] = useState([])
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [openPopup, setOpenPopup] = useState(false);
  const [user, setUser] = useState({});
  const isLoading = useSelector((state) => state.user.isLoading);
  // loading state for delete user
  const [loading, setLoading] = useState(false);


  useEffect(() => {

    getData()
  }, [])

  async function getData() {
    const tutorsData = await getTutorListRequest()

    setTutors(tutorsData)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tutors.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDeleteTutor = async (userId) => {
    await deleteTutorRequest(userId)
    await getData()
    setOpenPopup(false)
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tutors.length) : 0;

  const filteredTutors = applySortFilter(tutors, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredTutors.length === 0;

  // hide popup if loading is false
  useEffect(() => {
    if (!isLoading) setOpenPopup(false);
  }, [isLoading]);


  return (
    <Page title="Tutors List | Zeyuni dashboard">
      <TransitionsDialogs
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        itemId={user?.id}
        loading={isLoading}
        setLoading={setLoading}
        handleDelete={handleDeleteTutor}
      />
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Tutors"
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.tuitions.root }, { name: 'Tutors' }]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.tuitions.createTutor}
              startIcon={<Icon icon={plusFill} />}
            >
              Add Tutor
            </Button>
          }
        />


        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tutors.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredTutors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, fullName, email, status, phoneNumber, avatar } = row;
                    const isItemSelected = selected.indexOf(fullName) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, fullName)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={fullName} src={avatar} />
                            <Box sx={{ minWidth: '100px' }}>
                              <Typography variant="subtitle2" noWrap>
                                {fullName}
                              </Typography>
                              <Typography variant="body2" color="text.secondary" noWrap>
                                {email}
                              </Typography>
                            </Box>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{phoneNumber}</TableCell>
                        {/* <TableCell align="left">
                          {subjects?.slice(0, 2)?.map((subject) => (
                            <Chip key={subject.id} label={subject?.title || subject?.name} size="small" />
                          ))}
                          {subjects?.length > 2 && (
                            <Chip
                              key={subjects[2].id}
                              label={`+${subjects?.length - 2}`}
                              size="small"
                              sx={{ ml: 1 }}
                              color="primary"
                              variant="filled"
                            />
                          )}
                        </TableCell> */}

                        <TableCell align="left">
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={(status === 'inactive' && 'error') || 'success'}
                          >
                            {sentenceCase(status)}
                          </Label>
                        </TableCell>

                        <TableCell align="right">
                          <TutorMoreMenu
                            onDelete={() => {
                              setOpenPopup(true);
                              setUser(row);
                            }}
                            userId={id || ''}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[15, 25, 50, { label: 'All', value: tutors.length }]}
            component="div"
            count={tutors.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
