import { useEffect } from 'react';

import { useParams, useLocation } from 'react-router-dom';
// material
import { Container } from '@mui/material';
// redux
import { useDispatch } from '../../../../redux/store';
import { getUserList } from '../../../../redux/slices/user';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useSettings from '../../../../hooks/useSettings';
// components
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import ClassNewForm from 'src/components/_dashboard/tuitions/ClassNewForm';


// ----------------------------------------------------------------------

export default function AddClass() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { name } = useParams();

  const isEdit = pathname.includes('edit');

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  return (
    <Page title="Create a new tutor | Zeyuni dashboard">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Create a new tutor' : ' Edit tutor'}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Tutors', href: PATH_DASHBOARD.tuitions.tutors },
            { name: !isEdit ? 'New Tutor' : name }
          ]}
        />

        <ClassNewForm />
        
      </Container>
    </Page>
  );
}
