import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useCallback, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams, useSearchParams, } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@mui/lab';

import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  FormHelperText,
  FormControlLabel,
  Autocomplete,
  Button,
  Chip
} from '@mui/material';
import { fData } from '../../../utils/formatNumber';
import { PATH_DASHBOARD } from '../../../routes/paths';
//
import Label from '../../Label';
import { UploadAvatar } from '../../upload';
import { addClassRequest, getTutorListRequest, updateClassRequest } from '../../../_apis_/tuitions';
// ----------------------------------------------------------------------

ClassNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentClass: PropTypes.object
};

export default function ClassNewForm({ isEdit = false, currentClass }) {
  console.log({ currentClass })
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [tutors, setTutors] = useState()

  const [searchParams] = useSearchParams();
  const title = searchParams.get('title');

  const { id } = useParams()

  useEffect(() => {
    async function getData() {
      const data = await getTutorListRequest()
      setTutors(data)
    }

    getData()
  }, [])

  const [dayTimePickerSchedule, setDayTimePickerSchedule] = useState(
    currentClass?.schedule || [{ day: '', time: '' }]
  );

  const newClassGuideSchema = Yup.object().shape({
    tutor: Yup.object().required('Tutor is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: currentClass?.title || '',
      tutor: currentClass?.tutor || null,
      schedule: currentClass?.schedule || [],
    },
    validationSchema: newClassGuideSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {

        if (isEdit) {

          await updateClassRequest(id, values);

          setSubmitting(false);
          enqueueSnackbar('Updated CLass', { variant: 'success' });
        } else {
          const createdClass = await addClassRequest(values);

          // resetForm();
          setSubmitting(false);
          enqueueSnackbar('Created Class', { variant: 'success' });

          navigate(`${PATH_DASHBOARD.tuitions.viewClass}/${createdClass.id}`);
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;


  useEffect(() => {
    setFieldValue('schedule', dayTimePickerSchedule);
  }, [dayTimePickerSchedule, setFieldValue]);


  return (
    <FormikProvider value={formik}>
      <Form
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();

          handleSubmit();
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <h2>{title}</h2>


                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 3, sm: 2 }}>
                  <Autocomplete
                    disabled={!tutors}
                    freeSolo
                    value={values.tutor}
                    onChange={(event, newValue) => {
                      console.log({ newValue })
                      setFieldValue('tutor', newValue);
                    }}
                    options={tutors || []}
                    getOptionLabel={(option) => option.fullName}
                    renderTags={(value, getTagProps) =>
                      <Chip {...getTagProps({ index })} key={value?.id} label={value?.fullName} />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tutor"
                        placeholder="Tutor"
                        error={Boolean(touched.tutor && errors.tutor)}
                        helperText={touched.tutor && errors.tutor}
                      />
                    )}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 3, sm: 2 }}>
                  <DayTimePicker schedule={dayTimePickerSchedule} setSchedule={setDayTimePickerSchedule} />
                </Stack>

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    {!isEdit ? 'Add Class' : 'Save Changes'}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

function DayTimePicker({ schedule, setSchedule }) {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const handleAddField = () => {
    setSchedule([...schedule, { day: '', time: '' }]);
  };

  const handleRemoveField = (index) => {
    const updatedSchedule = [...schedule];
    updatedSchedule.splice(index, 1);
    setSchedule(updatedSchedule);
  };

  // const handleChangeInput = (index, event) => {
  //   const values = [...schedule];
  //   values[index][event.target.name] = event.target.value;
  //   setSchedule(values);
  // };
  const handleChangeInput = (index, event) => {
    const values = [...schedule];
    const updatedScheduleEntry = { ...values[index] }; // Create a copy of the schedule entry
    updatedScheduleEntry[event.target.name] = event.target.value; // Update the specific property
    values[index] = updatedScheduleEntry; // Replace the old schedule entry with the updated one
    setSchedule(values);
  };

  return (
    <Box>
      {schedule.map((field, idx) => (
        <Grid
          sx={{
            mt: 2
          }}
          container
          spacing={3}
          key={idx}
        >
          <Grid item xs={6}>
            <TextField
              select
              name="day"
              value={field.day}
              onChange={(event) => handleChangeInput(idx, event)}
              label="Select a day"
              fullWidth
              SelectProps={{ native: true }}
            >
              <option value="">None</option>
              {daysOfWeek.map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="time"
              name="time"
              value={field.time}
              onChange={(event) => handleChangeInput(idx, event)}
              label="Select a time"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={() => handleRemoveField(idx)}>Remove</Button>
          </Grid>
        </Grid>
      ))}
      <Button onClick={handleAddField}>Add schedule</Button>
    </Box>
  );
}
