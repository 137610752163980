import React from 'react';
import { useQuery, gql } from '@apollo/client';

export const POSTS_QUERY = gql`
  query PostsByCategory($id: ID!) {
    category(id: $id) {
      name
      id
      posts(first: 500, where: { categoryNotIn: ["dGVybTo4Mw=="] }) {
        nodes {
          id
          title
          slug
          date
        }
      }
    }
  }
`;

export const CATEGORIES_QUERY = gql`
  query {
    categories(first: 500) {
      nodes {
        name
        count
        id
      }
    }
  }
`;
