import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import { PATH_DASHBOARD } from 'src/routes/paths';
import TutorNewForm from 'src/components/_dashboard/tuitions/TutorNewForm';
import { getTutorRequest } from 'src/_apis_/tuitions';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';



export default function ViewTutor() {
  const [currentTutor, setCurrentTutor] = useState()
  const { themeStretch } = useSettings();

  const { id } = useParams()

  useEffect(() => {
    async function getData() {
      const tutor = await getTutorRequest(id)
      setCurrentTutor(tutor)
    }

    getData()
  }, [])


  return (
    <Page title="Tutor profile |Zeyuni dashboard">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Profile"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Tutors', href: PATH_DASHBOARD.tuitions.tutors },
            { name: 'Profile' }
          ]}
        />

        {currentTutor &&
          <TutorNewForm
            isEdit
            currentTutor={currentTutor}
          />
        }
      </Container>
    </Page>
  );
}
