import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Box, Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

// const CHART_DATA = [{ data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380] }];
function processData(data) {
  const categories = data.map((item) => item.range);
  const values = data.map((item) => item.percentage);

  return { categories, values };
}
export default function CareerRanges({ ranges = [], loading }) {
  const { categories, values } = processData(ranges);
  console.log(values, 'check range values here');
  const CHART_DATA = [{ data: values }];
  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => ''
        }
      }
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '28%', borderRadius: 2 }
    },
    xaxis: {
      categories
    }
  });

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <Card>
      <CardHeader title="Salary ranges" subheader="Current submissions" />
      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart type="bar" series={CHART_DATA} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
