import { createSlice } from '@reduxjs/toolkit';
import { getAllSalaries, getSalariesByProgram } from '../../_apis_/Salaries';

const salarySlice = createSlice({
  name: 'salary',
  initialState: {
    salaries: [],
    loading: false,
    error: null,
    programSubmissions: {}
  },
  reducers: {
    getSalariesStart(state) {
      state.loading = true;
      state.error = null;
    },
    getSalariesSuccess(state, action) {
      state.salaries = action.payload;
      state.loading = false;
      state.error = null;
    },
    getSalariesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    addSalaryStart(state) {
      state.loading = true;
      state.error = null;
    },
    addSalarySuccess(state, action) {
      state.salaries.push(action.payload);
      state.loading = false;
      state.error = null;
    },
    addSalaryFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteSalaryStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteSalarySuccess(state, action) {
      state.salaries = state.salaries.filter((salary) => salary.id !== action.payload);
      state.loading = false;
      state.error = null;
    },
    deleteSalaryFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getProgramSubmissionsStart(state) {
      state.loading = true;
      state.error = null;
    },
    getProgramSubmissionsSuccess(state, action) {
      state.programSubmissions = action.payload;
      state.loading = false;
      state.error = null;
    },
    getProgramSubmissionsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const {
  getSalariesStart,
  getSalariesSuccess,
  getSalariesFailure,
  addSalaryStart,
  addSalarySuccess,
  addSalaryFailure,
  deleteSalaryStart,
  deleteSalarySuccess,
  deleteSalaryFailure,
  getProgramSubmissionsStart,
  getProgramSubmissionsSuccess,
  getProgramSubmissionsFailure
} = salarySlice.actions;

export default salarySlice.reducer;

export const fetchSalaries = () => async (dispatch) => {
  dispatch(getSalariesStart());
  try {
    getAllSalaries().then((res) => {
      dispatch(getSalariesSuccess(res.allSalaries));
    });
  } catch (error) {
    dispatch(getSalariesFailure(error.message));
  }
};

export const fetchProgramSubmissions = (id) => async (dispatch) => {
  dispatch(getProgramSubmissionsStart());
  try {
    getSalariesByProgram(id).then((res) => {
      console.log(res, 'res');
      dispatch(getProgramSubmissionsSuccess(res));
    });
  } catch (error) {
    dispatch(getProgramSubmissionsFailure(error.message));
  }
};
