import { serverBaseURL } from './constants';

const axios = require('axios');

export const api = axios.create({
  baseURL: serverBaseURL,
  headers: {
    Authorization: 'Bearer YOUR_AUTH_TOKEN_HERE'
  }
});

// create a request to get all salaries
export const getAllSalaries = async () => {
  const response = await api.get('/salaries/group');
  return response.data;
};

export const getSalariesByProgram = async (programId) => {
  const response = await api.post(`/salaries/dashboard/${programId}`);

  return response.data;
};
