import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useParams } from 'react-router-dom';

// material

import { Stack, Container, Typography } from '@mui/material';
// redux

import CareerRanges from '../../../components/_dashboard/general-analytics/CareerRange';
import LoadingScreen from '../../../components/LoadingScreen';
import { useDispatch, useSelector } from '../../../redux/store';
import { getUserList } from '../../../redux/slices/user';
import { fetchProgramSubmissions } from '../../../redux/slices/salary';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';

import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import CareerTable from '../../components-overview/material-ui/data-grid/CareerSubmissionsTable';

export default function CareerProgram() {
  const { themeStretch } = useSettings();
  const { programSubmissions: program, loading } = useSelector((state) => state.salary);

  const { programId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserList());
    dispatch(fetchProgramSubmissions(programId));
  }, [dispatch, programId]);

  if (loading) {
    return <LoadingScreen />;
  }
  console.log(program.salaryRangePercentages, 'programSubmissions');
  return (
    <Page title={`${program.programName || 'Loading'} - Submissions | Zeyuni Dashboard`}>
      <Container
        maxWidth={themeStretch ? false : 'lg'}
        sx={{
          minHeight: '100vh'
        }}
      >
        <HeaderBreadcrumbs
          heading={`${program.programName}`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Submissions', href: PATH_DASHBOARD.careers.root },
            { name: program.programName }
          ]}
          action={null}
        />
        <Stack
          sx={{
            height: '100%'
          }}
        >
          {/* <RouterLink to="/dashboard/salaries" variant="contained" startIcon={<Icon icon={plusFill} />}>
            Back
          </RouterLink> */}
          <Stack>
            <CareerRanges ranges={program.salaryRangePercentages} loading={loading} />
          </Stack>
          <Typography variant="h4" sx={{ mt: 10 }}>
            Submissions
          </Typography>
          <Stack
            sx={{
              minHeight: '600px'
            }}
          >
            <CareerTable data={program.salaries} loading={loading} />
          </Stack>
        </Stack>
      </Container>
    </Page>
  );
}
