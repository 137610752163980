import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useCallback, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@mui/lab';

import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  FormHelperText,
  FormControlLabel,
  Autocomplete,
  Button,
  Chip
} from '@mui/material';
import { fData } from '../../../utils/formatNumber';
import { PATH_DASHBOARD } from '../../../routes/paths';
//
import Label from '../../Label';
import { UploadAvatar } from '../../upload';
import { addTutorRequest, getSubjectsRequest, updateTutorRequest } from '../../../_apis_/tuitions';
// ----------------------------------------------------------------------

TutorNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentTutor: PropTypes.object
};

export default function TutorNewForm({ isEdit = false, currentTutor }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  // const [subjects, setSubjects] = useState()

  // useEffect(() => {
  //   async function getData() {
  //     const subjectData = await getSubjectsRequest()
  //     setSubjects(subjectData)
  //   }

  //   getData()
  // }, [])
  // const [dayTimePickerSchedule, setDayTimePickerSchedule] = useState(
  //   currentTutor?.daysAvailable || [{ day: '', time: '' }]
  // );

  const newTutorGuideSchema = Yup.object().shape({
    fullName: Yup.string().required('Full name is required'),
    email: Yup.string().required('Email is required').email(),
    phoneNumber: Yup.string().required('Phone number is required'),
    // subjects: Yup.array().required('Subjects is required'),
    about: Yup.string().required('About is required'),
    avatar: Yup.mixed()
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: currentTutor?.fullName || '',
      email: currentTutor?.email || '',
      phoneNumber: currentTutor?.phoneNumber || '',
      // subjects: currentTutor?.subjects || [],
      about: currentTutor?.about || '',
      avatar: currentTutor?.avatar || null,
      status: currentTutor?.status || 'active',
    },
    validationSchema: newTutorGuideSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        if (values.avatar) {
          values.avatar = values.avatar.base64;
        }
        let subjectId;

        if (isEdit) {
          subjectId = currentTutor.id;
          await updateTutorRequest(subjectId, values);

          setSubmitting(false);
          enqueueSnackbar('Updated Tutor', { variant: 'success' });
        } else {
          const createdTutor = await addTutorRequest(values);

          // resetForm();
          setSubmitting(false);
          enqueueSnackbar('Created Tutor', { variant: 'success' });

          navigate(`${PATH_DASHBOARD.tuitions.viewTutor}/${createdTutor.id}`);
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFieldValue('avatar', {
            ...file,
            preview: URL.createObjectURL(file),
            base64: reader.result
          });
        };
        reader.readAsDataURL(file);
      }
    },
    [setFieldValue]
  );

  // useEffect(() => {
  //   setFieldValue('daysAvailable', dayTimePickerSchedule);
  // }, [dayTimePickerSchedule, setFieldValue]);

  // }

  return (
    <FormikProvider value={formik}>
      <Form
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();

          handleSubmit();
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ py: 10, px: 3 }}>
              <Label
                color={values.status !== 'active' ? 'error' : 'success'}
                sx={{ textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }}
              >
                {values.status}
              </Label>

              <Box sx={{ mb: 5 }}>
                <UploadAvatar
                  accept="image/*"
                  file={values.avatar}
                  maxSize={3145728}
                  onDrop={handleDrop}
                  error={Boolean(touched.avatar && errors.avatar)}
                  caption={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary'
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of {fData(3145728)}
                    </Typography>
                  }
                />
                <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                  {touched.avatar && errors.avatar}
                </FormHelperText>
              </Box>

              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    onChange={(event) => setFieldValue('status', event.target.checked ? 'inactive' : 'active')}
                    checked={values.status !== 'active'}
                  />
                }
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Set to inactive
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Disable account
                    </Typography>
                  </>
                }
                sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
              />
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Full Name"
                    {...getFieldProps('fullName')}
                    error={Boolean(touched.fullName && errors.fullName)}
                    helperText={touched.fullName && errors.fullName}
                  />

                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>

                  <TextField
                    fullWidth
                    label="Email Address"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />

                  <TextField
                    fullWidth
                    label="Phone Number"
                    {...getFieldProps('phoneNumber')}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />

                </Stack>
{/* 
                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 3, sm: 2 }}>
                  <Autocomplete
                    disabled={!subjects}
                    multiple
                    freeSolo
                    value={values.subjects}
                    onChange={(event, newValue) => {
                      setFieldValue('subjects', newValue);
                    }}
                    options={subjects || []}
                    getOptionLabel={(option) => option.title} // specify what property to use for the option label
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip {...getTagProps({ index })} key={option?.id} label={option?.title} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Subjects"
                        placeholder="Subjects"
                        error={Boolean(touched.subjects && errors.subjects)}
                        helperText={touched.subjects && errors.subjects}
                      />
                    )}
                  />
                </Stack> */}

                <TextField
                  fullWidth
                  multiline
                  minRows={4}
                  maxRows={4}
                  label="About"
                  {...getFieldProps('about')}
                  error={Boolean(touched.about && errors.about)}
                  helperText={touched.about && errors.about}
                />
                {/* 
                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 3, sm: 2 }}>
                  <DayTimePicker schedule={dayTimePickerSchedule} setSchedule={setDayTimePickerSchedule} />
                </Stack> */}

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    {!isEdit ? 'Add Tutor' : 'Save Changes'}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

function DayTimePicker({ schedule, setSchedule }) {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const handleAddField = () => {
    setSchedule([...schedule, { day: '', time: '' }]);
  };

  const handleRemoveField = (index) => {
    const updatedSchedule = [...schedule];
    updatedSchedule.splice(index, 1);
    setSchedule(updatedSchedule);
  };

  // const handleChangeInput = (index, event) => {
  //   const values = [...schedule];
  //   values[index][event.target.name] = event.target.value;
  //   setSchedule(values);
  // };
  const handleChangeInput = (index, event) => {
    const values = [...schedule];
    const updatedScheduleEntry = { ...values[index] }; // Create a copy of the schedule entry
    updatedScheduleEntry[event.target.name] = event.target.value; // Update the specific property
    values[index] = updatedScheduleEntry; // Replace the old schedule entry with the updated one
    setSchedule(values);
  };

  return (
    <Box>
      {schedule.map((field, idx) => (
        <Grid
          sx={{
            mt: 2
          }}
          container
          spacing={3}
          key={idx}
        >
          <Grid item xs={6}>
            <TextField
              select
              name="day"
              value={field.day}
              onChange={(event) => handleChangeInput(idx, event)}
              label="Select a day"
              fullWidth
              SelectProps={{ native: true }}
            >
              <option value="">None</option>
              {daysOfWeek.map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="time"
              name="time"
              value={field.time}
              onChange={(event) => handleChangeInput(idx, event)}
              label="Select a time"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={() => handleRemoveField(idx)}>Remove</Button>
          </Grid>
        </Grid>
      ))}
      <Button onClick={handleAddField}>Add schedule</Button>
    </Box>
  );
}
