/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@iconify/react';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';

import { useNavigate } from 'react-router';

// material

import { Typography, Box, Rating, Pagination } from '@mui/material';
import {
  DataGrid,
  useGridSlotComponentProps,
  getGridNumericColumnOperators,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbar
} from '@mui/x-data-grid';
// utils
import { useSelector, useDispatch } from 'react-redux';

// components
import { MIconButton } from '../../../../components/@material-extend';
import { fetchSalaries } from '../../../../redux/slices/salary';
// ----------------------------------------------------------------------

const columns = [
  // OPTIONS
  // https://material-ui.com/api/data-grid/grid-col-def/#main-content
  // - hide: false (default)
  // - editable: false (default)
  // - filterable: true (default)
  // - sortable: true (default)
  // - disableColumnMenu: false (default)

  // FIELD TYPES
  // --------------------
  // 'string' (default)
  // 'number'
  // 'date'
  // 'dateTime'
  // 'boolean'
  // 'singleSelect'

  {
    field: 'id',
    hide: true
  },

  {
    field: 'category',
    headerName: 'Category',
    flex: 1
  },
  {
    field: 'firstJobSalary',
    headerName: 'First job salary',
    flex: 1
  },
  {
    field: 'currentJobSalary',
    headerName: 'Current job salary',
    flex: 1
  },
  {
    field: 'levelOfEducation',
    headerName: 'Level of education',
    flex: 1
  },
  {
    field: 'employed',
    headerName: 'Employed',
    flex: 1
  },
  {
    field: 'yearsOfExperience',
    headerName: 'Years of experience',
    flex: 1
  },
  {
    field: 'yearFirstEmployed',
    headerName: 'Year first employed',
    flex: 1
  },
  {
    field: 'yearOfEntryPlanned',
    headerName: 'Year of entry planned',
    flex: 1
  },
  {
    field: 'yearOfGraduation',
    headerName: 'Year of graduation',
    flex: 1
  },

  {
    field: 'action',
    headerName: ' ',
    width: 80,
    align: 'right',
    sortable: false,
    filterable: false,
    disableColumnMenu: false,
    renderCell: (params) => {
      const selectedID = params.row.id;

      const handleClick = () => {
        console.log('selectedID', selectedID);
      };

      return (
        <MIconButton onClick={handleClick}>
          <Box component={Icon} icon={moreVerticalFill} sx={{ width: 20, height: 20 }} />
        </MIconButton>
      );
    }
  }
];

// ----------------------------------------------------------------------

function CustomPagination() {
  const { state, apiRef } = useGridSlotComponentProps();

  return (
    <Pagination
      color="primary"
      count={state.pagination.pageCount}
      page={state.pagination.page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

RatingInputValue.propTypes = {
  applyValue: PropTypes.func.isRequired,
  item: PropTypes.shape({
    columnField: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    operatorValue: PropTypes.string,
    value: PropTypes.any
  }).isRequired
};

function RatingInputValue({ item, applyValue }) {
  return (
    <Box sx={{ p: 1, height: 1, alignItems: 'flex-end', display: 'flex' }}>
      <Rating
        size="small"
        precision={0.5}
        placeholder="Filter value"
        value={Number(item.value)}
        onChange={(event, newValue) => {
          applyValue({ ...item, value: newValue });
        }}
      />
    </Box>
  );
}

export default function CareerTable({ loading, data = [] }) {
  const navigate = useNavigate();
  if (columns.length > 0) {
    const ratingColumn = columns.find((column) => column.field === 'rating');
    const ratingColIndex = columns.findIndex((col) => col.field === 'rating');

    const ratingFilterOperators = getGridNumericColumnOperators().map((operator) => ({
      ...operator,
      InputComponent: RatingInputValue
    }));

    columns[ratingColIndex] = {
      ...ratingColumn,
      filterOperators: ratingFilterOperators
    };
  }

  console.log({ data }, 'data');

  return (
    <DataGrid
      // checkboxSelection
      loading={loading}
      disableSelectionOnClick
      onCellClick={(params, event) => {
        navigate(`/dashboard/salaries/${params.id}`);
      }}
      rows={data}
      columns={columns}
      pagination
      pageSize={10}
      components={{
        Toolbar: CustomToolbar,
        Pagination: CustomPagination
      }}
    />
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
