import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useSnackbar } from 'notistack';

import { Link as RouterLink } from 'react-router-dom';
// material
import { useTheme } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Chip,
  Dialog
} from '@mui/material';
import TransitionsDialogs from '../../../../components/DeleteItemDialog';
// redux
import { useSelector } from '../../../../redux/store';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useSettings from '../../../../hooks/useSettings';
// components
import Page from '../../../../components/Page';
import Label from '../../../../components/Label';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { deleteSubjectRequest, getSubjectsRequest } from 'src/_apis_/tuitions';
import SubjectMoreMenu from 'src/components/_dashboard/tuitions/list/SubjectMoreMenu';
import { UserListToolbar } from 'src/components/_dashboard/user/list';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'Subject', label: 'Name', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      const lowerCaseQuery = query.toLowerCase();
      return (
        (_user.title && _user.title.toLowerCase().indexOf(lowerCaseQuery) !== -1));
    });
  }
  return stabilizedThis.map((el) => el[0]);
}
export default function Subjects() {
  const { themeStretch } = useSettings();
  const [subjects, setSubjects] = useState([])
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [openPopup, setOpenPopup] = useState(false);
  const [user, setUser] = useState({});
  const isLoading = useSelector((state) => state.user.isLoading);
  // loading state for delete user
  const [loading, setLoading] = useState(false);


  useEffect(() => {

    getData()
  }, [])

  async function getData() {
    const subjectsData = await getSubjectsRequest()

    setSubjects(subjectsData)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = subjects.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDeleteSubject = async (userId) => {
    await deleteSubjectRequest(userId)
    await getData()
    setOpenPopup(false)
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - subjects.length) : 0;

  const filteredsubjects = applySortFilter(subjects, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredsubjects.length === 0;

  // hide popup if loading is false
  useEffect(() => {
    if (!isLoading) setOpenPopup(false);
  }, [isLoading]);


  return (
    <Page title="Subjects List | Zeyuni dashboard">
      <TransitionsDialogs
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        itemId={user?.id}
        loading={isLoading}
        setLoading={setLoading}
        handleDelete={handleDeleteSubject}
      />
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Subjects"
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.tuitions.root }, { name: 'subjects' }]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.tuitions.createSubject}
              startIcon={<Icon icon={plusFill} />}
            >
              Add Subject
            </Button>
          }
        />

        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />


        <Card>
          <subjectsToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <subjectsHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={subjects.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredsubjects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, title } = row;
                    const isItemSelected = selected.indexOf(title) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >

                        <TableCell align="left">{title}</TableCell>



                        <TableCell align="right">
                          <SubjectMoreMenu
                            onDelete={() => {
                              setOpenPopup(true);
                              setUser(row);
                            }}
                            userId={id || ''}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[15, 25, 50, { label: 'All', value: subjects.length }]}
            component="div"
            count={subjects.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
