import propTypes from 'prop-types';

import { useSnackbar } from 'notistack';
import { forwardRef, useState } from 'react';
// material
import { Slide, Dialog, Button, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { deleteCareerGuideRequest } from '../_apis_/CareerGuides';

import { useDispatch, useSelector } from '../redux/store';
import { deleteCareerGuide } from '../redux/slices/user';
// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

Transition.propTypes = {
  ref: propTypes.oneOfType([propTypes.object, propTypes.func])
};

TransitionsDialogs.propTypes = {
  openPopup: propTypes.bool,
  setOpenPopup: propTypes.func,
  handleDelete: propTypes.func,
  loading: propTypes.bool,
  itemId: propTypes.string

};

// ----------------------------------------------------------------------

export default function TransitionsDialogs({ openPopup, itemId, setOpenPopup, handleDelete, loading }) {
  // const [loading, setLoading] = useState(false);
  // const dispatch = useDispatch();
  // const { enqueueSnackbar } = useSnackbar();
  // const handleClickOpen = () => {
  //   setOpenPopup(true);
  // };

  // const handleDeleteGuide = async (userId) => {
  //   try {
  //     setLoading(true);
  //     const response = await deleteCareerGuideRequest(userId);
  //     setLoading(false);
  //     setOpenPopup(false);
  //     dispatch(deleteCareerGuide(userId));
  //     enqueueSnackbar('Guide deleted successfully', { variant: 'success' });

  //     return response;
  //   } catch (error) {
  //     setLoading(false);
  //     return error;
  //   }
  // };

  const handleClose = () => {
    setOpenPopup(false);
    // if (!loading) setOpenPopup(false);
  };

  return (
    <Dialog
      open={openPopup}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">Delete</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Are you sure you sure you want to delete? This action cannot be undone.
          <br />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          disabled={loading}
          variant="contained"
          color="error"
          onClick={() => {
            // handleDeleteGuide(user.id);
            handleDelete(itemId);
            // handleClose();
          }}
        >
          {loading ? 'Deleting...' : 'Delete'}
        </LoadingButton>
        <Button color="inherit" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
