import PropTypes from 'prop-types';
import CarerrGuideNewForm from './CareerGuideNewForm';

// ----------------------------------------------------------------------

Profile.propTypes = {
  myProfile: PropTypes.object,
  posts: PropTypes.array,
  isEdit: PropTypes.bool
};

export default function Profile({ myProfile, isEdit }) {
  return (
    <>
      <CarerrGuideNewForm isEdit={isEdit} currentCareerGuide={myProfile} />
    </>
  );
}
