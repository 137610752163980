import { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';

const GET_POSTS = gql`
  query {
    posts(first: 500, where: { categoryNotIn: "dGVybTo4Mw==" }) {
      edges {
        node {
          id
          title
          categories {
            nodes {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export function usePostsByCategories() {
  const [allPosts, setAllPosts] = useState([]);

  const { loading, error, data } = useQuery(GET_POSTS);

  useEffect(() => {
    if (data) {
      setAllPosts(
        data.posts.edges.map((post) => {
          const categoryId = post.node.categories.nodes[0]?.id;
          return {
            id: post.node.id,
            title: post.node.title,
            categoryId
          };
        })
      );
    }
  }, [data]);

  return { allPosts, loading, error };
}
