import { api } from "./Salaries";


export const getTutorListRequest = async () => {
  const response = await api.get('/tutor');
  const tutorsData = response.data;

  return tutorsData.map(tutor => ({
    ...tutor,
    subjects: JSON.parse(tutor.subjects)
  }))
};

export const addTutorRequest = async (data) => {
  const response = await api.post('/tutor', data);
  return response.data;
};

export const getTutorRequest = async (id) => {
  const response = await api.get(`/tutor/${id}`);
  const data = response.data;

  return {
    ...data,
    subjects: JSON.parse(data.subjects)
  }
};

export const updateTutorRequest = async (id, data) => {
  const response = await api.post(`/tutor/${id}`, data);
  console.log(data, 'data from updateTutorRequest');
  console.log(response, 'response from updateTutorRequest');
  return response.data;
};

export const deleteTutorRequest = async (id) => {
  const response = await api.delete(`/tutor/${id}`);
  return response.data;
};

export const getSubjectsRequest = async () => {
  const response = await api.get('/subject');
  return response.data;
};


export const deleteSubjectRequest = async (id) => {
  const response = await api.delete(`/subject/${id}`);
  return response.data;
};

export const addSubject = async (data) => {
  const response = await api.post('/subject', data);
  return response.data;
};

// 

export const getClassListRequest = async () => {
  const response = await api.get('/class/v2/all');
  const classData = response.data;



  return classData.map(classData => {

    let tutor = {}
    let schedule = []

    if (classData.Tutor) {
      tutor = {
        ...classData.Tutor,
      }
    }

    if (classData.schedule) {
      schedule = JSON.parse(classData.schedule)
    }


    return {
      ...classData,
      schedule,
      tutor
    }
  })
};

export const addClassRequest = async (data) => {
  const response = await api.post('/class', data);
  return response.data;
};

export const getClassRequest = async (id) => {
  const response = await api.get(`/class/v2/${id}`);
  const data = response.data;

  let tutor = {}
  let schedule = []

  if (data.Tutor) {
    tutor = data.Tutor
  }

  if (data.schedule) {
    schedule = JSON.parse(data.schedule)
  }

  return {
    ...data,
    schedule,
    tutor
  }
};

export const updateClassRequest = async (id, data) => {
  const response = await api.post(`/class/v2/${id}`, data);
  console.log(data, 'data from updateClassRequest');
  console.log(response, 'response from updateClassRequest');
  return response.data;
};

export const deleteClassRequest = async (id) => {
  const response = await api.delete(`/class/${id}`);
  return response.data;
};