import { useEffect } from 'react';

import { Stack, Container } from '@mui/material';
// redux
import { useDispatch } from '../../redux/store';
import { getUserList } from '../../redux/slices/user';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';

import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

import SalariesTable from '../components-overview/material-ui/data-grid/SalariesTable';

export default function SalariesList() {
  const { themeStretch } = useSettings();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  return (
    <Page title="Salaries Submitted | Zeyuni Dashboard">
      <Container
        maxWidth={themeStretch ? false : 'lg'}
        sx={{
          height: '70vh'
        }}
      >
        <HeaderBreadcrumbs
          heading="Salaries"
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.careers.root }, { name: 'Submissions' }]}
          action={null}
        />
        <Stack
          sx={{
            height: '100%'
          }}
        >
          <SalariesTable />
        </Stack>
      </Container>
    </Page>
  );
}
