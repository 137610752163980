// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    salaries: path(ROOTS_DASHBOARD, '/salaries'),
    sessions: path(ROOTS_DASHBOARD, '/sessions'),
    tuitions: path(ROOTS_DASHBOARD, '/tuitions'),

  },
  careers: {
    root: path(ROOTS_DASHBOARD, '/salaries')
  },
  sessions: {
    root: path(ROOTS_DASHBOARD, '/sessions'),
    careerGuides: path(ROOTS_DASHBOARD, '/sessions/career-guides'),
    sessionsList: path(ROOTS_DASHBOARD, '/sessions/list'),
    // feedback: path(ROOTS_DASHBOARD, '/sessions/feedback'),
    createGuide: path(ROOTS_DASHBOARD, '/sessions/new-guide'),
    viewGuide: path(ROOTS_DASHBOARD, '/sessions/view-guide/')
  },

  tuitions: {
    root: path(ROOTS_DASHBOARD, '/tuitions'),
    tutors: path(ROOTS_DASHBOARD, '/tuitions/tutors'),
    createTutor: path(ROOTS_DASHBOARD, '/tuitions/tutors/new'),
    viewTutor: path(ROOTS_DASHBOARD, '/tuitions/tutors/view'),
    classes: path(ROOTS_DASHBOARD, '/tuitions/classes'),
    createClass: path(ROOTS_DASHBOARD, '/tuitions/classes/new'),
    viewClass: path(ROOTS_DASHBOARD, '/tuitions/classes/view'),
    subjects: path(ROOTS_DASHBOARD, '/tuitions/subjects'),
    createSubject: path(ROOTS_DASHBOARD, '/tuitions/subjects/new'),


  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
