import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { getClassRequest  } from 'src/_apis_/tuitions';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import ClassNewForm from 'src/components/_dashboard/tuitions/ClassNewForm';



export default function ViewClass() {
  const [currentClass, setcurrentClass] = useState()
  const { themeStretch } = useSettings();

  const { id } = useParams()

  useEffect(() => {
    async function getData() {
      const classData = await getClassRequest(id)
      setcurrentClass(classData)
    }

    getData()
  }, [])


  return (
    <Page title="Class |Zeyuni dashboard">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Class"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Class', href: PATH_DASHBOARD.tuitions.classes },
            { name: 'Details' }
          ]}
        />

        {currentClass &&
          <ClassNewForm
            isEdit
            currentClass={currentClass}
          />
        }
      </Container>
    </Page>
  );
}
