import { useCallback, useState, useEffect } from 'react';
import { Container } from '@mui/material';
// redux
import { useDispatch } from '../../../../redux/store';
import { getUserList } from '../../../../redux/slices/user';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useSettings from '../../../../hooks/useSettings';
// components
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { addSubject } from 'src/_apis_/tuitions';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import {
  Box,
  Card,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';


// ----------------------------------------------------------------------

export default function AddSubject() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);


  const newSubjectSchema = Yup.object().shape({
    title: Yup.string().required('Subject name is required'),
  });


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: '',

    },
    validationSchema: newSubjectSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {


        await addSubject(values);

        // resetForm();
        setSubmitting(false);
        enqueueSnackbar('Created Subject', { variant: 'success' });

        navigate(`${PATH_DASHBOARD.tuitions.subjects}`);

      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });


  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;


  return (
    <Page title="Create a new Subject | Zeyuni dashboard">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={'Create a new Subject'}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Subjects', href: PATH_DASHBOARD.tuitions.subjects },
            { name: 'New Subject' }
          ]}
        />
        {/*  */}
        <FormikProvider value={formik}>
          <Form
            noValidate
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();

              handleSubmit();
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Subject Name"
                        {...getFieldProps('title')}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                      />
                    </Stack>

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        Add Subject
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
        {/*  */}
      </Container>
    </Page>
  );
}
